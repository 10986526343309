import React from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  InputLabel,
  DialogContent,
  MenuList,
  ListItemText,
  TextareaAutosize,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Box, spacing } from "@mui/system";

import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import ModalAproveAccount from "../components/modal-approve-account";

const Paper = styled(MuiPaper)(spacing);
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3)
];


function ScoreLevelBsComponent(props) {

  const {business_account} = props;
  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
        Dados do Score - {business_account.scoreLevel?business_account.scoreLevel.points:"Não processado o score"}
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        <Grid item md={6} xs="12" >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Atributo</TableCell>
              <TableCell align="left">Pontos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {business_account.scoreLevel &&
            business_account.scoreLevel.attributes &&
            business_account.scoreLevel.attributes
              .sort((a, b) => a.points - b.points)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.extended_name}
                  </TableCell>
                  <TableCell align="left">{row.points}</TableCell>
                </TableRow>
              ))}


          </TableBody>
        </Table>
        </Grid>
      </Grid>
    </Paper>
  </Card>
  );
}



export default ScoreLevelBsComponent;
